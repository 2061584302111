import ApiService from '@/common/api.service'
import {
  getToken,
  ID_TOKEN_KEY,
  saveToken,
} from '@/common/localStorage.service'
import { makeApolloClient } from '@/common/apollo'
import {
  LOGIN,
  CHECK_AUTH,
  LOGOUT,
  GET_USER_PROJECTS,
  USER_JOBS,
} from '../actions.type'
import {
  SET_AUTH,
  SET_USER,
  PURGE_AUTH,
  PURGE_PROJECTS,
} from '../mutations.type'

const actions = {
  async [LOGIN](context, data) {
    // Set access token in auth header
    saveToken(ID_TOKEN_KEY, data.token)
    await ApiService.setHeader()
    await makeApolloClient()
    context.commit(SET_AUTH, data)
    await context.dispatch(GET_USER_PROJECTS)
    await context.dispatch(USER_JOBS)
  },

  async [CHECK_AUTH](context) {
    if (getToken(ID_TOKEN_KEY)) {
      if (!context.getters.isAuthenticated) {
        await ApiService.setHeader()

        // Set user
        await ApiService.get('/me')
          .then(({ data }) => {
            context.commit(SET_USER, data.data)
          })
          .catch(() => {
            context.commit(PURGE_AUTH)
          })
      }
    } else {
      context.commit(PURGE_AUTH)
    }
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
    context.commit(PURGE_PROJECTS)
  },
}

export default actions

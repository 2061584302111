// FOR AUTH
export const LOGIN = 'login'
export const CHECK_AUTH = 'checkAuth'
export const LOGOUT = 'logout'

// FOR PROJECTS
export const CREATE_PROJECT = 'createProject'
export const MOCK_CREATE_PROJECT = 'mockCreateProject'
export const SAVE_PROJECT = 'saveProject'
export const UPDATE_PROJECT = 'updateProject'
export const RENAME_PROJECT = 'renameProject'
export const GET_PROJECT_BY_ID = 'getProjectById'
export const GET_USER_PROJECTS = 'getUserProjects'
export const GENERATE_NEW_ACCESS_URL = 'generateNewAccessUrl'
export const HANDLE_URL = 'handleUrl'

export const LOAD_PROJECT = 'loadProject'
export const OPEN_PROJECT = 'openProject'
export const CLOSE_PROJECT = 'closeProject'
export const ACTIVATE_PROJECT = 'activateProject'
export const DEACTIVATE_PROJECT = 'deactivateProject'

export const RUN = 'run'
export const STOP = 'stop'
export const UNDO = 'undo'
export const REDO = 'redo'
export const ADD_COLLABORATOR = 'addCollaborator'
export const SET_PROJECT_OWNER = 'setProjectOwner'
export const REMOVE_COLLABORATOR = 'removeCollaborator'

// FOR PUBLISHING
export const GET_PUBLISHED_PROJECTS = 'getPublishedProjects'
export const PUBLISH_PROJECT = 'publishProject'
export const MAKE_COPY_FROM_PUBLISHED_PROJECT = 'makeCopyFromPublishedProject'

// FOR NEWS
export const FETCH_NEWS = 'fetchNews'

// FOR JOBS
export const USER_JOBS = 'userJobs'
export const GET_JOB_DETAILS_BY_ID = 'getJobDetailsById'
export const JOBS_BY_PROJECT_ID = 'jobsByProjectId'

// FOR USERS
export const SEARCH_USER = 'searchUser'

<template>
  <Modal :isOpen="!!component" :title="title" @onClose="handleOutsideClick">
    <component
      :is="component"
      @onClose="handleClose"
      @onCloseAndReturn="handleCloseAndReturn"
      v-bind="props"
    />
  </Modal>
</template>

<script>
import { ModalBus } from '@/common/eventBus'
import Modal from '@/components/modals/ModalBus/Modal'

export default {
  name: 'ModalRoot',
  data() {
    return {
      component: null,
      title: '',
      props: null,
      closeOnClick: true,
    }
  },
  created() {
    ModalBus.$on(
      'open',
      ({ component, title = '', props = null, closeOnClick = true }) => {
        this.component = component
        this.title = title
        this.props = props
        this.closeOnClick = closeOnClick
      }
    )
    document.addEventListener('keyup', this.handleKeyup)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyup)
  },
  methods: {
    handleOutsideClick() {
      if (!this.closeOnClick) return
      this.handleClose()
    },
    handleClose() {
      this.component = null
    },
    handleCloseAndReturn() {
      this.handleClose()
      ModalBus.$emit('msgBack', '')
    },
    handleKeyup(e) {
      if (e.keyCode === 27) this.handleClose()
    },
  },
  components: { Modal },
}
</script>

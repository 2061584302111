import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import router from './router'
import VueKatex from 'vue-katex'
import VueRouter from 'vue-router'
import VueApollo from 'vue-apollo'
import { GridGlobal } from 'gridjs-vue'
import { BootstrapVue } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlayCircle, faSearch, faCheck, faChevronDown, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faGithub, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { apolloClient } from "./common/apollo"
import utilityFunctions from './common/utilityFunctions'

// Add global functions
Vue.prototype.$utilFunc = utilityFunctions
Vue.prototype.$axios = axios
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_HTTP_URL
Vue.prototype.$devMode = true // devmode
Vue.config.productionTip = false

// Add Vue plugins
Vue.use(VueKatex, { globalOptions: { strict: false } })
Vue.use(VueApollo)
Vue.use(GridGlobal)
Vue.use(VueRouter)
Vue.use(BootstrapVue)

// Register Font Awesome
library.add(faGoogle, faGithub, faLinkedinIn, faFacebookF, faPlayCircle, faSearch, faCheck, faChevronDown, faClipboard)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  store,
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

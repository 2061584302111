import { apolloClient } from '@/common/apollo'
import gql from 'graphql-tag'
import {
  USER_JOBS,
  JOBS_BY_PROJECT_ID,
  GET_JOB_DETAILS_BY_ID,
} from '../actions.type'
import { SET_USER_JOBS } from '@/store/mutations.type'

const actions = {
  async [USER_JOBS](context) {
    return await apolloClient
      .query({
        query: gql`
          query getUserJobs {
            getUserJobs {
              id
              status
              projectData
              result
              createdAt
              updatedAt
              ProjectId
              UserId
            }
          }
        `,
      })
      .then(({ data }) => {
        context.commit(SET_USER_JOBS, data.getUserJobs)
        return data.getUserJobs
      })
  },

  async [JOBS_BY_PROJECT_ID](context, ProjectId) {
    const { data } = await apolloClient.query({
      query: gql`
        query getJobsByProjectId($ProjectId: ID!) {
          getJobsByProjectId(ProjectId: $ProjectId) {
            id
            celeryId
            status
            projectData
            result
            createdAt
            updatedAt
            ProjectId
            UserId
          }
        }
      `,
      variables: {
        ProjectId,
      },
    })
    return data.getJobsByProjectId
  },

  async [GET_JOB_DETAILS_BY_ID](context, jobId) {
    const { data } = await apolloClient.query({
      query: gql`
        query getJobDetailsById($jobId: ID!) {
          getJobDetailsById(jobId: $jobId) {
            job {
              id
              celeryId
              status
              projectData
              result
              createdAt
              updatedAt
              UserId
              ProjectId
            }
            projectName
            collaborators {
              id
              firstName
              lastName
              userName
              avatar
              ProjectPermission {
                permissionType
              }
            }
          }
        }
      `,
      variables: {
        jobId,
      },
    })
    return {
      ...data.getJobDetailsById.job,
      projectName: data.getJobDetailsById.projectName,
      collaborators: data.getJobDetailsById.collaborators,
    }
  },
}

export default actions

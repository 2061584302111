import {
  SET_USER_PROJECTS,
  SET_LOAD_PROJECT,
  SET_OPEN_PROJECT,
  SET_CLOSE_PROJECT,
  SET_ACTIVATED_PROJECT,
  SET_DEACTIVATED_PROJECT,
  CHANGE_CURRENT_PROJECT,
  PURGE_PROJECTS,
  CHANGE_HBAR,
  CHANGE_CUTOFF,
  CHANGE_MEASUREMENT_CUTOFF,
  CHANGE_SHOTS,
  SET_PARAM,
  SET_UPDATE_PROJECT,
  UPDATE_PROJECT_COLLABORATORS,
  UPDATE_PROJECT_SHARE_URLS,
  ERROR_CHECKED,
} from '../mutations.type'
import { Project } from '@/store/project/Project'

function findProjectByProperty(state, property, value) {
  return state.loadedProjects.filter((x) => {
    return x[property] === value
  })
}

const mutations = {
  [SET_USER_PROJECTS](state, userProjects) {
    state.userProjects = userProjects || []
  },

  /** Loads a project with basic information and saves it in the loadedProjects state **/
  [SET_LOAD_PROJECT](state, projectInfo) {
    console.log('load_project')
    const project = new Project(state, projectInfo) // payload.projectScheme, payload.projectData)
    state.loadedProjects.push(project)
  },

  /**
   * Opens a project by initializing and setting up its composer object along with other configurations needed for displaying
   * (We assume that the basic project information has already been loaded before and stored in state.loadedProjects in SET_LOAD_PROJECT)
   **/
  [SET_OPEN_PROJECT](state, projectId) {
    const project = findProjectByProperty(state, 'id', projectId)[0]
    project.openProject(state)
  },

  /** Closes an opened project/tab **/
  [SET_CLOSE_PROJECT](state, closedProject) {
    // First fetch the necessary data for deciding which project to activate next automatically
    const isActive = closedProject.isActive
    const openedProjects = findProjectByProperty(state, 'isOpened', true)
    const closedProjectIndex = openedProjects.findIndex((x) => x.id === closedProject.id)

    // Close project and remove it from the openedProjects array
    closedProject.closeProject()
    const numOpenedProjects = openedProjects.length - 1

    // If there are no more projects opened in tabs
    if (!numOpenedProjects) {
      return
    }

    // If the closed project was the active before
    if (isActive) {
      const newActiveProjectIndex =
        closedProjectIndex !== numOpenedProjects
          ? closedProjectIndex
          : closedProjectIndex - 1

      openedProjects[newActiveProjectIndex].activate(state)
    }
  },

  /**
   * Set one of the loaded projects active for displaying
   * (We assume that the project has already been opened and set up for displaying in SET_OPEN_PROJECT )
   **/
  [SET_ACTIVATED_PROJECT](state, projectId) {
    const project = findProjectByProperty(state, 'id', projectId)[0]
    project.activate(state)
  },

  /**
   * Deactivates the currently activated project (if there is one)
   *
   * project (optional): If set, the given project is deactivated without searching for the active one, otherwise
   * we look for the active project first and then deactivate it
   * **/
  [SET_DEACTIVATED_PROJECT](state, project) {
    if (project === undefined) {
      project = findProjectByProperty(state, 'isActive', true)[0]
    }

    if (project) {
      project.deactivate()
    }
  },

  /** Changes the currently activated project **/
  [CHANGE_CURRENT_PROJECT](state, projectToActivateId) {
    const activeProject = findProjectByProperty(state, 'isActive', true)[0]
    if (activeProject) {
      activeProject.deactivate()
    }

    const projectToActivate = findProjectByProperty(
      state,
      'id',
      projectToActivateId
    )[0]
    projectToActivate.activate(state)
  },

  /** Removes a given project's data from the store **/
  [PURGE_PROJECTS](state) {
    state.currentDoc = ''
    state.currentParams = undefined
    state.userProjects = []
    state.loadedProjects = []
  },

  /** Changes 'hbar' simulation parameter **/
  [CHANGE_HBAR](state, newVal) {
    const activeProject = findProjectByProperty(state, 'isActive', true)[0]
    activeProject.projectData.config.hbar = newVal
    activeProject.isSaved = false
  },

  /** Changes 'cutoff' simulation parameter **/
  [CHANGE_CUTOFF](state, newVal) {
    const activeProject = findProjectByProperty(state, 'isActive', true)[0]
    activeProject.projectData.config.cutoff = newVal
    activeProject.isSaved = false
  },

  /** Changes 'measurement cutoff' simulation parameter **/
  [CHANGE_MEASUREMENT_CUTOFF](state, newVal) {
    const activeProject = findProjectByProperty(state, 'isActive', true)[0]
    activeProject.projectData.config.measurement_cutoff = newVal
    activeProject.isSaved = false
  },

  /** Changes 'shots' simulation parameter **/
  [CHANGE_SHOTS](state, newVal) {
    const activeProject = findProjectByProperty(state, 'isActive', true)[0]
    activeProject.projectData.shots = newVal
    activeProject.isSaved = false
  },

  /** Sets the parameter of a Composer gate specified by the 'key' attribute **/
  [SET_PARAM](state, [key, value]) {
    const activeProject = findProjectByProperty(state, 'isActive', true)[0]
    activeProject.setParam(key, value)
  },

  /** Updates a given project's fields after saving that (can) change on backend during saving **/
  async [SET_UPDATE_PROJECT](state, payload) {
    const project = findProjectByProperty(state, 'id', payload.projectId)[0]
    project.name = payload.name
    project.updatedAt = payload.updatedAt
  },

  /** Updates a given projects collaborators field **/
  async [UPDATE_PROJECT_COLLABORATORS](state, payload) {
    const project = findProjectByProperty(state, 'id', payload.projectId)[0]
    project.collaborators = payload.collaborators
  },

  /** Updates a given projects share urls field **/
  async [UPDATE_PROJECT_SHARE_URLS](state, payload) {
    const project = findProjectByProperty(state, 'id', payload.projectId)[0]
    project.shareUrls = payload.shareUrls
  },

  /** Clears simulation related error messages **/
  [ERROR_CHECKED](state) {
    const activeProject = findProjectByProperty(state, 'isActive', true)[0]
    activeProject.error = null
  },
}

export default mutations

<template>
  <div id="app">
    <router-view />
    <ModalRoot />
  </div>
</template>

<script>
import { ModalBus } from '@/common/eventBus'
import { toastConfig } from '@/config/toast_config'
import ModalRoot from '@/components/modals/ModalBus/ModalRoot'
import CircuitErrorModal from '@/components/modals/circuit-error-modal'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './main.css'

export default {
  name: 'App',
  components: {
    ModalRoot,
  },
  created() {
    this.$store.watch(
      (state, getters) => getters.getToastMessages,
      (oldValue) => {
        const actElement = oldValue.slice(-1)[0]
        this.$bvToast.toast(actElement.message, {
          ...toastConfig[actElement.type],
          autoHideDelay: 3000,
          toaster: 'b-toaster-bottom-center',
        })
      }
    )

    this.$store.watch(
      (state, getters) => getters.getError,
      (oldValue) => {
        if (oldValue) {
          ModalBus.$emit('open', {
            component: CircuitErrorModal,
            title: 'Error',
            props: { error: oldValue.error },
          })
        }
      }
    )
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@import '../node_modules/katex/dist/katex.min.css';

html,
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--pq-blue-shade1);
}

#app {
  height: 100%;
  width: 100%;
}

#profile-dropdown__BV_toggle_ {
  padding: 0;
}

#profile-dropdown__BV_toggle_:focus {
  box-shadow: none;
}

.card-header {
  border-bottom: 1px solid var(--pq-color-border);
}
</style>

// Create the apollo client
import { ApolloClient, DefaultOptions } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split, from } from 'apollo-link'
import { getToken, ID_TOKEN_KEY } from './localStorage.service'
import { getMainDefinition } from 'apollo-utilities'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { onError } from 'apollo-link-error'
import store from '@/store'
import router from '@/router'
import { ADD_TOAST_MESSAGE } from '@/store/mutations.type'

const getHeaders = () => {
  const headers = {
    authorization: undefined
  }
  const token = getToken(ID_TOKEN_KEY)
  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  return headers
}

// HTTP connection to the API
const makeHttpLink = () => createHttpLink({
  // You should use an absolute URL here
  uri: `${process.env.VUE_APP_HTTP_URL}/graphql`,
  fetch,
  headers: getHeaders()
})

const wsLink = new WebSocketLink({
  uri: `${process.env.VUE_APP_WS_URL}/subscriptions`,
  options: {
    reconnect: true
  }
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const makeLink = () => split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
  },
  wsLink,
  makeHttpLink()
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.forEach(({ message }) => {
    if (message !== 'Not authenticated!') {
      store.commit(ADD_TOAST_MESSAGE, {message: `Request error: ${message}`, type: 'error'})
    }
  }

)

  if (networkError) {
    store.commit(ADD_TOAST_MESSAGE, { message: `Network error: ${networkError}` })
  }
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

export let apolloClient

export const makeApolloClient = () => {
  apolloClient = new ApolloClient({
    link: from([errorLink, makeLink()]),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  })
}

makeApolloClient()

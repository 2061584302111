import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import project from './project'
import publish from './publish'
import news from './news'
import job from './job'
import user from './user'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    project,
    publish,
    news,
    job,
    user
  }
})

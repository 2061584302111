import VueRouter from 'vue-router'
import authGuard from './authGuard'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/layout/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/layout/DashboardLayout.vue'),
        children: [
          {
            path: '/',
            name: 'dashboard-home',
            component: () => import('@/pages/DashboardHome.vue'),
          },
          {
            path: '/projects',
            name: 'dashboard-projects',
            component: () => import('@/pages/DashboardProjects.vue'),
          },
          {
            path: '/projects/:projectId',
            name: 'dashboard-projectDetails',
            component: () => import('@/pages/DashboardProjectDetails.vue'),
          },
          {
            path: '/jobs/:jobId',
            name: 'dashboard-jobDetails',
            component: () => import('@/pages/DashboardJobDetails.vue'),
          },
          {
            path: '/share/:projectUrl',
            name: 'read-share-link',
            component: () => import('@/pages/SharedProjectUrl.vue'),
          },
          {
            path: '/published',
            name: 'dashboard-published',
            component: () => import('@/pages/DashboardPublished.vue'),
          }
        ]
      },
      {
        path: '/',
        name: 'composer-layout',
        component: () => import('@/layout/ComposerLayout.vue'),
        children: [
          {
            path: '/composer/:projectId?',
            name: 'composer',
            component: () => import('@/pages/ComposerPage.vue'),
            props: true
          },
          {
            path: '/published/:projectId',
            name: 'published',
            component: () => import('@/pages/ComposerPage.vue'),
            props: true
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// route middlewares
authGuard(router)

export default router

import Vue from 'vue'
import { getToken, ID_TOKEN_KEY } from './localStorage.service'

const ApiService = {
  setHeader() {
    Vue.prototype.$axios.defaults.headers.common.Authorization = `Bearer ${getToken(
      ID_TOKEN_KEY
    )}`
  },
  get(resource) {
    return Vue.prototype.$axios.get(`${resource}`)
  },
}

export default ApiService

import { apolloClient } from '@/common/apollo'
import { SEARCH_USER } from '../actions.type'
import gql from 'graphql-tag'

const actions = {
  async [SEARCH_USER](context, str) {
    return await apolloClient
      .query({
        query: gql`
          query searchUser($str: String!) {
            searchUser(str: $str) {
              id
              firstName
              lastName
              userName
              avatar
            }
          }
        `,
        variables: {
          str: str,
        },
      })
      .then(({ data }) => {
        return data.searchUser
      })
  },
}

export default actions

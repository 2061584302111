import gql from 'graphql-tag'
import { apolloClient } from '@/common/apollo'
import { v4 as uuidv4 } from 'uuid'

import {
  CREATE_PROJECT,
  MOCK_CREATE_PROJECT,
  UPDATE_PROJECT,
  RENAME_PROJECT,
  GET_PROJECT_BY_ID,
  GET_USER_PROJECTS,
  GENERATE_NEW_ACCESS_URL,
  HANDLE_URL,
  LOAD_PROJECT,
  OPEN_PROJECT,
  CLOSE_PROJECT,
  ACTIVATE_PROJECT,
  DEACTIVATE_PROJECT,
  RUN,
  STOP,
  UNDO,
  REDO,
  ADD_COLLABORATOR,
  SET_PROJECT_OWNER,
  REMOVE_COLLABORATOR,
  SAVE_PROJECT,
} from '../actions.type'

import {
  SET_USER_PROJECTS,
  SET_LOAD_PROJECT,
  SET_OPEN_PROJECT,
  SET_CLOSE_PROJECT,
  SET_ACTIVATED_PROJECT,
  SET_DEACTIVATED_PROJECT,
  SET_UPDATE_PROJECT,
  UPDATE_PROJECT_COLLABORATORS,
  UPDATE_PROJECT_SHARE_URLS,
  ADD_TOAST_MESSAGE,
} from '../mutations.type'

const actions = {
  /**
   * Creates a new project
   * The project is first created on the backend side so its ID can be fetched and used on client side for creation
   **/
  async [CREATE_PROJECT](context, payload) {
    const { data } = await apolloClient.mutate({
      mutation: gql`
        mutation createProject($name: String!, $description: String) {
          createProject(name: $name, description: $description) {
            id
            name
            description
            projectData
            visibility
            collaborators {
              id
              firstName
              lastName
              userName
              avatar
              ProjectPermission {
                permissionType
              }
            }
            sharedUrls {
              readAccessUrl
              writeAccessUrl
            }
            updatedAt
            createdAt
          }
        }
      `,
      variables: {
        name: payload.name,
        description: ""
      },
    })

    // Load project information in loadedProjects state, setup composer and save configured circuit
    const newProjectInfo = data.createProject
    newProjectInfo.newScheme = payload.scheme

    context.commit(SET_LOAD_PROJECT, newProjectInfo)
    context.commit(SET_OPEN_PROJECT, newProjectInfo.id)
    context.commit(ADD_TOAST_MESSAGE, {
      message: 'Project created',
      type: 'success',
    })
    await context.dispatch(SAVE_PROJECT, newProjectInfo.id)
    return newProjectInfo.id
  },

  /**
   * Creates a new project
   * The project is first created on the backend side so its ID can be fetched and used on client side for creation
   **/
  async [MOCK_CREATE_PROJECT](context, payload) {
    const mockProject = {}
    mockProject.id = uuidv4()
    mockProject.name = payload.name
    mockProject.visibility = 'private'
    mockProject.newScheme = payload.scheme
    mockProject.projectData = {}

    context.commit(SET_LOAD_PROJECT, mockProject)
    context.commit(SET_OPEN_PROJECT, mockProject.id)
    context.commit(ADD_TOAST_MESSAGE, {
      message: 'Project created',
      type: 'success',
    })

    return mockProject.id
  },

  /**
   * Save a project
   * projectId (optional): If set, project with the given ID is saved, otherwise the currently active project is saved
   **/
  async [SAVE_PROJECT](context, projectId) {
    let project = {}
    if (projectId !== undefined) {
      project = context.getters.getProjectById(projectId)
    } else {
      project = context.getters.getCurrentProject
    }
    console.log('saving..')

    await context.dispatch(UPDATE_PROJECT, {
      ProjectId: project.id,
      name: project.name,
      projectData: project.exportJson()
    })

    project.isSaved = true
  },

  async [UPDATE_PROJECT](context, payload) {
    const { data } = await apolloClient.mutate({
      mutation: gql`
        mutation updateProject(
          $ProjectId: ID!
          $name: String
          $projectData: JsonType
        ) {
          updateProject(
            ProjectId: $ProjectId
            name: $name
            description: ""
            projectData: $projectData
          ) {
            id
            name
            description
            projectData
            visibility
            updatedAt
          }
        }
      `,
      variables: payload,
    })

    context.commit(SET_UPDATE_PROJECT, {
      projectId: payload.ProjectId,
      name: data.updateProject.name,
      updatedAt: data.updateProject.updatedAt,
    })
  },

  async [RENAME_PROJECT](context, payload) {
    await context.dispatch(UPDATE_PROJECT, {
      ProjectId: payload.projectId,
      name: payload.newProjectName,
    })
  },

  async [GET_PROJECT_BY_ID](context, ProjectId) {
    console.log('Fetching project by id..')
    const { data } = await apolloClient.query({
      query: gql`
        query getProjectById($ProjectId: ID!) {
          getProjectById(ProjectId: $ProjectId) {
            id
            name
            description
            projectData
            visibility
            collaborators {
              id
              firstName
              lastName
              userName
              avatar
              ProjectPermission {
                permissionType
              }
            }
            sharedUrls {
              readAccessUrl
              writeAccessUrl
            }
            updatedAt
            createdAt
          }
        }
      `,
      variables: {
        ProjectId: ProjectId,
      },
    })

    console.log('Project has been fetched successfully!')
    return data.getProjectById
  },
  async [GET_USER_PROJECTS](context) {
    return await apolloClient
      .query({
        query: gql`
          query getUserProjects {
            getUserProjects {
              id
              name
              description
              collaborators {
                id
                firstName
                lastName
                userName
                avatar
                ProjectPermission {
                  permissionType
                }
              }
              updatedAt
            }
          }
        `,
      })
      .then(({ data }) => {
        context.commit(SET_USER_PROJECTS, data.getUserProjects)
        return data.getUserProjects
      })
  },
  async [GENERATE_NEW_ACCESS_URL](context, payload) {
    const { data } = await apolloClient.query({
      query: gql`
        mutation generateProjectUrl($ProjectId: ID!, $accessLevel: String!) {
          generateProjectUrl(ProjectId: $ProjectId, accessLevel: $accessLevel) {
            readAccessUrl
            writeAccessUrl
          }
        }
      `,
      variables: {
        ProjectId: payload.ProjectId,
        accessLevel: payload.accessLevel,
      },
    })

    context.commit(UPDATE_PROJECT_SHARE_URLS, {
      projectId: payload.ProjectId,
      shareUrls: data.generateProjectUrl,
    })

    return data.generateProjectUrl
  },

  async [HANDLE_URL](context, shareUrl) {
    return apolloClient
      .query({
        query: gql`
          mutation shareProjectWithUrl($shareUrl: String!) {
            shareProjectWithUrl(shareUrl: $shareUrl)
          }
        `,
        variables: {
          shareUrl,
        },
      })
      .then(({ data }) => {
        return data.shareProjectWithUrl
      })
  },

  /**
   * Loads a project
   * If the project hasn't been loaded before, it first fetches the project information from the backend side
   */
  async [LOAD_PROJECT](context, projectId) {
    const project = context.getters.getProjectById(projectId)
    if (!project && projectId) {
      // Error handling is needed in case the backend doesn't return data (because of permission or other issues)
      const fetchedProject = await context.dispatch(
        GET_PROJECT_BY_ID,
        projectId
      )

      if (fetchedProject === null) {
        throw 'Not fetched'
      }

      console.log('Loading project..')
      context.commit(SET_LOAD_PROJECT, fetchedProject)
      console.log('Project has been loaded!')
    }
  },

  /**
   * Opens a Piquasso project.
   * This function also makes sure that the project has been loaded and opened previously
   */
  async [OPEN_PROJECT](context, projectId) {
    // First make sure that the project information is loaded
    await context.dispatch(LOAD_PROJECT, projectId)

    // Next init composer and save project if it hasn't been done before
    const project = context.getters.getProjectById(projectId)
    if (!project.isOpened) {
      console.log('Opening project..')
      context.commit(SET_OPEN_PROJECT, projectId)
      console.log('Project opened!')
    }
  },

  /** Closes an opened project **/
  async [CLOSE_PROJECT](context, project) {
    context.commit(SET_CLOSE_PROJECT, project)
  },

  /**
   * Activates a project with the given project ID and deactivates the previously active project
   * The activated project needs to be loaded and opened prior
   **/
  async [ACTIVATE_PROJECT](context, projectId) {
    // First make sure that the project has been loaded and opened correctly
    await context.dispatch(OPEN_PROJECT, projectId)

    // Deactivate current project (if there is one) and activate new one
    const activeProject = context.getters.getCurrentProject
    if (activeProject) {
      console.log('deactivating prev project?')
      context.commit(SET_DEACTIVATED_PROJECT, activeProject)
    }

    console.log('activating project')
    // Activate project
    context.commit(SET_ACTIVATED_PROJECT, projectId)
  },

  async [DEACTIVATE_PROJECT](context) {
    context.commit(SET_DEACTIVATED_PROJECT)
  },

  async [RUN](context) {
    const currentProject = context.getters.getCurrentProject
    if (!currentProject.isSaved && context.getters.isAuthenticated) {
      await context.dispatch(SAVE_PROJECT)
    }
    context.getters.getCurrentProject.run()
  },

  async [STOP](context) {
    context.getters.getCurrentProject.stop()
  },

  async [UNDO](context) {
    context.getters.getCurrentProject.undo()
  },

  async [REDO](context) {
    context.getters.getCurrentProject.redo()
  },

  /** Share project with new user (or change existing users permission read <--> write) **/
  async [ADD_COLLABORATOR](context, payload) {
    const { data } = await apolloClient.query({
      query: gql`
        mutation shareProject(
          $UserId: ID!
          $ProjectId: ID!
          $permissionType: String!
        ) {
          shareProject(
            UserId: $UserId
            ProjectId: $ProjectId
            permissionType: $permissionType
          ) {
            id
            firstName
            lastName
            userName
            avatar
            ProjectPermission {
              permissionType
            }
          }
        }
      `,
      variables: {
        UserId: payload.UserId,
        ProjectId: payload.ProjectId,
        permissionType: payload.permissionType,
      },
    })

    context.commit(UPDATE_PROJECT_COLLABORATORS, {
      projectId: payload.ProjectId,
      collaborators: data.shareProject,
    })
  },

  /** Sets another collaborator as the project owner **/
  async [SET_PROJECT_OWNER](context, payload) {
    const { data } = await apolloClient.query({
      query: gql`
        mutation setProjectOwner($UserId: ID!, $ProjectId: ID!) {
          setProjectOwner(UserId: $UserId, ProjectId: $ProjectId) {
            id
            firstName
            lastName
            userName
            avatar
            ProjectPermission {
              permissionType
            }
          }
        }
      `,
      variables: {
        UserId: payload.UserId,
        ProjectId: payload.ProjectId,
      },
    })

    context.commit(UPDATE_PROJECT_COLLABORATORS, {
      projectId: payload.ProjectId,
      collaborators: data.setProjectOwner,
    })
  },

  /** Removes a collaborator from the project **/
  async [REMOVE_COLLABORATOR](context, payload) {
    const { data } = await apolloClient.query({
      query: gql`
        mutation removeCollaborator($UserId: ID!, $ProjectId: ID!) {
          removeCollaborator(UserId: $UserId, ProjectId: $ProjectId) {
            id
            firstName
            lastName
            userName
            avatar
            ProjectPermission {
              permissionType
            }
          }
        }
      `,
      variables: {
        UserId: payload.UserId,
        ProjectId: payload.ProjectId,
      },
    })

    context.commit(UPDATE_PROJECT_COLLABORATORS, {
      projectId: payload.ProjectId,
      collaborators: data.removeCollaborator,
    })
  },
}

export default actions

import { apolloClient } from '@/common/apollo'
import gql from 'graphql-tag'
import {
  GET_PUBLISHED_PROJECTS,
  PUBLISH_PROJECT,
  MAKE_COPY_FROM_PUBLISHED_PROJECT,
} from '../actions.type'
import { ADD_TOAST_MESSAGE } from '@/store/mutations.type'

const actions = {
  async [GET_PUBLISHED_PROJECTS](context, variables) {
    const { data } = await apolloClient.query({
      query: gql`
        query getPublishedProjects($start: Int, $stop: Int) {
          getPublishedProjects(start: $start, stop: $stop) {
            id
            name
            description
            owner {
              id
              firstName
              lastName
              userName
              avatar
              ProjectPermission {
                permissionType
              }
            }
            updatedAt
            createdAt
          }
        }
      `,
      variables,
    })

    return data.getPublishedProjects
  },

  async [PUBLISH_PROJECT](context, payload) {
    await apolloClient.mutate({
      mutation: gql`
        mutation publishProject(
          $ProjectId: ID!
          $name: String!
          $description: String!
        ) {
          publishProject(
            ProjectId: $ProjectId
            name: $name
            description: $description
          ) {
            id
          }
        }
      `,
      variables: {
        ProjectId: payload.ProjectId,
        name: payload.title,
        description: payload.description,
      },
    })

    context.commit(ADD_TOAST_MESSAGE, {
      message: 'Project published!',
      type: 'success',
    })
  },

  async [MAKE_COPY_FROM_PUBLISHED_PROJECT](context, PublishedId) {
    const { data } = await apolloClient.mutate({
      mutation: gql`
        mutation makeCopyFromPublished($PublishedId: ID!) {
          makeCopyFromPublished(PublishedId: $PublishedId) {
            id
          }
        }
      `,
      variables: {
        PublishedId: PublishedId,
      },
    })

    context.commit(ADD_TOAST_MESSAGE, {
      message: 'Project copied successfully!',
      type: 'success',
    })

    return data.makeCopyFromPublished
  },
}

export default actions

import store from '@/store'
import gql from 'graphql-tag'
import { apolloClient } from '@/common/apollo'
import { ADD_TOAST_MESSAGE } from '@/store/mutations.type'
import utilityFunctions from '@/common/utilityFunctions'
import {
  Composer,
  Scheme,
  schemeFromString,
} from '@budapest-quantum-computing-group/piquasso-composer'

export class Project {
  // Basic properties
  id = null
  name = ''
  visibility = ''
  projectData = {}
  collaborators = {}
  shareUrls = {}
  isSaved = true
  updatedAt = null
  createdAt = null

  // Required for displaying circuits
  htmlElement = null
  composer = null
  isOpened = false // Is composer set up already?
  isActive = false // Is the project the one displayed now?

  // Required for submitting jobs and displaying results
  error = null
  results = null
  celeryId = null
  isSubmitted = false

  // Defaults
  DEFAULT_CONFIG = {
    cutoff: 5,
    hbar: 2,
    measurement_cutoff: 5,
  }

  DEFAULT_SHOTS = 1

  constructor(state, projectInfo) {
    this.setBasicProjectInformation(projectInfo)
    this.setCircuitInformation(projectInfo)
  }

  setBasicProjectInformation(projectInfo) {
    this.id = projectInfo.id
    this.name = projectInfo.name
    this.visibility = projectInfo.visibility // private: composer, public: published
    this.collaborators = projectInfo?.collaborators || {}
    this.shareUrls = projectInfo?.sharedUrls || {}
    this.updatedAt = projectInfo?.updatedAt || null
    this.createdAt = projectInfo?.createdAt || null
  }

  setCircuitInformation(projectInfo) {
    // Use the circuit configurations if project parameters have been already initialized before
    // otherwise use the default parameters
    if (Object.keys(projectInfo.projectData).length) {
      this.projectData = projectInfo.projectData
    } else {
      this.setDefaultCircuitInformation(projectInfo.newScheme)
    }
  }

  setDefaultCircuitInformation(newScheme) {
    const circuitScheme = schemeFromString(newScheme)
    this.projectData.meta = {
      scheme: circuitScheme,
      maxRows: circuitScheme === Scheme.LinearOptics ? 8 : 4,
    }
    this.projectData.config = this.DEFAULT_CONFIG
    this.projectData.shots = this.DEFAULT_SHOTS
  }

  openProject(state) {
    this.isOpened = true
    this.htmlElement = this.makeDiv()
    //  Workaround for avoiding injecting the circuit into HTML too early
    if (!this.composer) {
      this.composer = new Composer(
        this.projectData.meta.scheme,
        this.id,
        this.projectData.meta.maxRows
      )
    }

    if (this.composer && 'program' in this.projectData) {
      this.composer = Composer.fromJson(this.projectData, this.id)
    }
    // Also could save projectId in local storage here
    this.registerSubscriptions(state)
  }

  closeProject() {
    this.htmlElement = null
    this.composer = null
    this.isOpened = false
    this.isActive = false
  }

  makeDiv() {
    const div = document.createElement('div')
    div.id = this.id
    div.style.width = '100%'
    div.style.overflowX = 'auto'
    document.getElementById('composer-container').appendChild(div)
    return div
  }

  activate(state) {
    // Check if the parent div exists
    if (!document.getElementById(this.id)) {
      // If no parent div is found, create html div first and open project
      this.reload(state)
    }
    this.isActive = true
    this.htmlElement.style.display = 'block'
    this.composer.redrawCircuit()
  }

  deactivate() {
    this.isActive = false
    this.htmlElement.style.display = 'none'
  }

  reload(state) {
    const program = this.exportJson()
    this.projectData = program
    this.destroy()
    this.openProject(state)
    this.composer.resetStateChanges()
  }

  registerSubscriptions(state) {
    this.composer.docChanged.subscribe((doc) => {
      state.currentDoc = doc
    })
    this.composer.paramsChanged.subscribe((params) => {
      state.currentParams = params
    })
    this.composer.stateChanged.subscribe((n) => {
      this.isSaved = this.id && n === 0
    })
  }

  destroy() {
    this.composer.destroy()
    this.composer = null
    this.htmlElement.remove()
  }

  exportJson() {
    this.syncWithComposer()
    return this.projectData
  }

  syncWithComposer() {
    const program = this.composer.exportJson()
    const projectData = {
      ...program,
      meta: this.projectData.meta,
      shots: this.projectData.shots,
      config: this.projectData.config,
    }
    this.projectData = projectData
  }

  setParam(key, value) {
    this.composer.setParameter(key, value)
    this.syncWithComposer()
    this.isSaved = false
  }

  async run() {
    this.isSubmitted = true
    const resp = await apolloClient.mutate({
      mutation: gql`
        mutation runProgram($projectData: JsonType!, $ProjectId: ID) {
          runProgram(projectData: $projectData, ProjectId: $ProjectId) {
            error
            taskId
          }
        }
      `,
      variables: {
        projectData: this.exportJson(),
        ProjectId: this.id,
      },
    })

    const {
      runProgram: { error, taskId },
    } = resp.data

    if (error) {
      this.results = undefined
      this.isSubmitted = false
      this.error = { error }
    }

    if (taskId) {
      this.celeryId = taskId
      this.subscribeToResult(taskId)
    }
  }

  async stop() {
    this.isSubmitted = false
    await apolloClient.mutate({
      mutation: gql`
        mutation stopProgram($celeryId: ID!) {
          stopProgram(celeryId: $celeryId)
        }
      `,
      variables: {
        celeryId: this.celeryId,
      },
    })
  }

  undo() {
    this.composer.undo()
  }

  redo() {
    this.composer.redo()
  }

  subscribeToResult(taskId) {
    const observer = apolloClient.subscribe({
      query: gql`
        subscription simulationFinished($taskId: ID!) {
          simulationFinished(taskId: $taskId)
        }
      `,
      variables: {
        taskId,
      },
    })

    // We need this to access `this` from the observer
    const saveResults = (x) => {
      this.isSubmitted = false
      this.results = utilityFunctions.preprocessResult(x)
    }
    const setError = (error) => {
      this.isSubmitted = false
      this.results = undefined
      this.error = { error }
    }

    observer.subscribe({
      next({ data }) {
        const {
          simulationFinished: { result, error },
        } = data

        if (result && !('error' in result)) {
          saveResults(result)
          store.commit(ADD_TOAST_MESSAGE, {
            message: 'Process finished',
            type: 'success',
          })
        } else {
          const errorMessage =
            result && 'error' in result ? result.error : error?.exc_message[0]
          setError(errorMessage)
          store.commit(ADD_TOAST_MESSAGE, {
            message: 'Error occured on process',
            type: 'error',
          })
        }
      },
      error(error) {
        console.error(error)
      },
    })
  }
}

<template>
  <transition name="slide-fade" appear>
    <div
      class="p-modal__backdrop"
      v-show="isOpen"
      :class="{ open: isOpen }"
      @click="$emit('onClose')"
    >
      <div class="p-modal__dialog" :class="{ open: isOpen }" @click.stop>
        <div class="p-modal__title" v-if="title">{{ title }}</div>
        <div class="p-modal__body">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isOpen: Boolean,
    title: String,
  },
}
</script>

<style scoped>
.p-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;

  /* Makes animation laggy as hell */
  /* backdrop-filter: blur(1px); */
}

.p-modal__dialog {
  min-width: 32rem;
  background: var(--pq-blue-shade1);
  padding: 1.5rem 1.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.p-modal__title {
  color: var(--pq-font-color-shade2);
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}

.p-modal__body {
  color: var(--pq-font-color-shade2);
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.slide-fade-enter .p-modal__dialog,
.slide-fade-leave-to .p-modal__dialog {
  transform: translateY(20%);
}
</style>

import { apolloClient } from '@/common/apollo'
import gql from 'graphql-tag'
import { FETCH_NEWS } from '../actions.type'

const actions = {
  async [FETCH_NEWS]() {
    return await apolloClient
      .query({
        query: gql`
          query getNews {
            getNews {
              title
              body
              createdAt
            }
          }
        `,
      })
      .then(({ data }) => {
        return data.getNews
      })
  },
}

export default actions

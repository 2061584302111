const getters = {
  getComposer: (state) =>
    state.loadedProjects.find((x) => x.isActive === true)?.composer,
  getProjectById: (state) => (projectId) =>
    state.loadedProjects.find((x) => x.id === projectId),
  getLoadedProjects: (state) => state.loadedProjects,
  getOpenedProjects: (state) =>
    state.loadedProjects.filter((x) => {
      return x.isOpened === true
    }),
  getCurrentProject: (state) =>
    state.loadedProjects.find((x) => x.isActive === true),
  getUserProjects: (state) => state.userProjects,
  getResults: (state) =>
    state.loadedProjects.find((x) => x.isActive === true)?.results,
  getError: (state) =>
    state.loadedProjects.find((x) => x.isActive === true)?.error,
  getDocumentation: (state) => state.currentDoc,
  getParams: (state) => state.currentParams,
}

export default getters

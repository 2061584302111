export const toastConfig = {
  error: {
    variant: 'danger',
    title: 'Error',
  },
  info: {
    variant: 'info',
    title: 'Info',
  },
  success: {
    variant: 'success',
    title: 'Success',
  },
}

import { destroyToken, ID_TOKEN_KEY } from '@/common/localStorage.service'
import { SET_USER, SET_AUTH, PURGE_AUTH } from '../mutations.type'
import { makeApolloClient } from '@/common/apollo'

const mutations = {
  [SET_USER](state, user) {
    state.isAuthenticated = true
    state.user = user
  },

  [SET_AUTH](state, data) {
    state.isAuthenticated = true
    state.user = data.user
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    destroyToken(ID_TOKEN_KEY)
    makeApolloClient()
  },
}

export default mutations

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

export default {
  convertTime: (date) => {
    const timeAgo = new TimeAgo()
    return timeAgo.format(new Date(date).getTime())
  },
  copyToClipboard: async (str) => {
    await navigator.clipboard.writeText(str)
  },
  preprocessResult: (results) => ({
    fockProbabilities: {
      data: results.fockProbabilities,
      x: results.fockProbabilities.map((x) => x.occupationNumber.join('')),
      y: results.fockProbabilities.map((x) => x.probability),
      csv: 'data:text/csv;charset=utf-8,occupationNumber,probability\n' + results.fockProbabilities.map(
        ({
          occupationNumber,
          probability
        }) => `${occupationNumber.join('')},${probability}`
      ).join('\n')
    },
    pythonCode: results.pythonCode,
    samples: {
      ...results.samples,
      currentModes: results.samples.modes,
      currentMode: results.samples.modes[0],
      csv: 'data:text/csv;charset=utf-8,' +
        results.samples.modes.map((m) => 'Qumode' + m).join(',') + '\n' +
        results.samples.data.map((s) => s.join(',')).join('\n')
    },
    wignerPlot: {
      data: results.wignerPlot,
      modes: results.wignerPlot.map((x) => x.modes[0]),
      currentMode: results.wignerPlot[0]?.modes[0]
    }
  })
}

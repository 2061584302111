import store from '../store'
import { CHECK_AUTH } from '@/store/actions.type'
import { ADD_TOAST_MESSAGE } from '@/store/mutations.type'

export default (router) => {
  router.beforeEach(async (to, from, next) => {
    await store.dispatch(CHECK_AUTH)

    // If the user is not authenticated and he is trying to access one of the routes - projects, composer - we redirect him to home page
    if (!store.getters.isAuthenticated && (to.path.startsWith('/projects') || to.path.startsWith('/jobs'))) {
      store.commit(ADD_TOAST_MESSAGE, { message: 'Authentication needed', type: 'error' })
      next({ path: '/' })
    } else {
      next()
    }
  })
}

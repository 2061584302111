<template>
  <div>
    <div style="white-space: pre-wrap">
      {{ error }}
    </div>

    <div class="modal-footer">
      <b-button
        style="float: right"
        class="mt-3 modal-close-ok-btn"
        @click="errorChecked"
      >
        Ok
      </b-button>
    </div>
  </div>
</template>

<script>
import { ERROR_CHECKED } from '@/store/mutations.type'

export default {
  name: 'CircuitErrorModal',
  props: ['error'],
  methods: {
    errorChecked: function () {
      this.$store.commit(ERROR_CHECKED)
      this.$emit('onClose')
    },
  },
}
</script>

<style scoped></style>

// FOR AUTH
export const SET_USER = 'setUser'
export const SET_AUTH = 'setAuth'
export const PURGE_AUTH = 'purgeAuth'

// FOR PROJECT
export const SET_USER_PROJECTS = 'setUserProjects'
export const SET_LOAD_PROJECT = 'setLoadProject'
export const SET_OPEN_PROJECT = 'setOpenProject'
export const SET_CLOSE_PROJECT = 'setCloseProject'
export const SET_ACTIVATED_PROJECT = 'setActivatedProject'
export const SET_DEACTIVATED_PROJECT = 'setDeactivatedProject'
export const CHANGE_CURRENT_PROJECT = 'changeCurrentProject'
export const PURGE_PROJECTS = 'purgeProjects'

export const CHANGE_HBAR = 'changeHBAR'
export const CHANGE_CUTOFF = 'changeCutoff'
export const CHANGE_MEASUREMENT_CUTOFF = 'changeMeasurementCutoff'
export const CHANGE_SHOTS = 'changeMeasurementCutoff'
export const SET_PARAM = 'setParam'

export const SET_UPDATE_PROJECT = 'setUpdateProject'
export const UPDATE_PROJECT_COLLABORATORS = 'updateProjectCollaborators'
export const UPDATE_PROJECT_SHARE_URLS = 'updateProjectShareUrls'
export const ERROR_CHECKED = 'errorChecked'

// FOR JOBS
export const SET_USER_JOBS = 'setUserJobs'

// FOR APPS
export const ADD_TOAST_MESSAGE = 'addToastMessage'
